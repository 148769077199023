<template>
  <div
    class="max-w-6xl mx-auto px-4 md:px-6 lg:px-10 min-h-screen flex jusify-center items-center"
  >
    <div
      class="w-3/4 flex flex-col justify-center items-center gap-4 bg-black p-12 mx-auto rounded-2xl"
    >
      <div class="flex flex-row gap-2">
        <h1 class="text-9xl text-white">4</h1>
        <img src="../assets/404.svg" class="h-24 mt-4" alt="bitcoin" />
        <h1 class="text-9xl text-white">4</h1>
      </div>
      <div class="text-white">
        <h1 class="text-orange text-4xl my-4 font-montserrat font-semibold">
          OOPS! PAGE NOT FOUND
        </h1>
        <p class="text-center font-montserrat font-light">
          Sorry, the page you're looking for doesn't exist!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");
.font-montserrat {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

div {
  font-family: "Bree Serif", serif;
}

img {
  transform: scale(1.65);
  opacity: 0.8;
}

.text-orange {
  color: #f7931a;
}
</style>
